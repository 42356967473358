/*
 * Main and demo navigation arrays
 *
 * 'to' attribute points to the route name, not the path url
 */

import type { RouteLocation } from 'vue-router';

export type BadgeVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
export type MenuItem = {
    badge?: string;
    'badge-variant'?: BadgeVariant;
    name: string;
    to?: string | RouteLocation;
    icon?: string;
    heading?: boolean;
    subActivePaths?: string;
    open?: boolean;
    sub?: MenuItem[];
    target?: string;
};

export type Menu = {
    [key: string]: MenuItem[];
};

export default {
    main: [
        {
            name: 'Dashboard',
            to: 'dashboard',
            icon: 'fa-solid fa-lg fa-gauge',
        },
        {
            name: 'Shipments',
            to: 'shipments',
            icon: 'fa-solid fa-lg fa-truck-fast',
        },
        {
            name: 'Products',
            to: 'products',
            icon: 'fa-solid fa-lg fa-box-open',
        },
        {
            name: 'SKUs',
            to: 'skus',
            icon: 'fa-solid fa-lg fa-barcode',
        },
        {
            name: 'Stock',
            to: 'stocks',
            icon: 'fa-solid fa-lg fa-boxes-stacked',
        },
        {
            name: 'Inventory',
            icon: 'fa-solid fa-lg fa-pallet',
            sub: [
                {
                    name: 'By Date',
                    to: 'inventory-by-date',
                    icon: 'fa-solid fa-lg fa-calendar-days',
                },
            ],
        },
        {
            name: 'Forecasts',
            to: 'forecasts',
            icon: 'fa-solid fa-chart-line',
        },
        {
            name: 'Sales Channels',
            to: 'sales-channels.list',
            icon: 'fa-solid fa-sitemap',
        },
        {
            name: 'Inventory',
            heading: true,
        },
        {
            name: 'Analysis',
            to: 'stocks-analysis',
            icon: 'fa-solid fa-lg fa-chart-pie',
        },
        {
            name: 'Purchase Orders',
            to: 'purchase-orders',
            icon: 'fa-solid fa-lg fa-file-invoice-dollar',
        },
        {
            name: 'Warehouse Transfers',
            to: 'transfers',
            icon: 'fa-solid fa-lg fa-people-carry-box',
            subActivePaths: 'transfers',
            open: true,
            sub: [
                {
                    name: 'All Transfers',
                    to: 'transfers',
                    icon: 'fa-solid fa-lg fa-right-left',
                },
                {
                    name: 'Upcoming Transfers',
                    to: {
                        name: 'transfers',
                        query: {
                            draft: 'true',
                            live: 'false',
                            completed: 'false',
                            sort: 'shipping_date',
                            sortDesc: 'false',
                        },
                    },
                    icon: 'fa-solid fa-lg fa-hourglass-half',
                },
                {
                    name: 'Problem Transfers',
                    to: {
                        name: 'transfers',
                        query: {
                            draft: 'true',
                            live: 'true',
                            completed: 'true',
                            problems: true,
                        },
                    },
                    icon: 'fa-solid fa-lg fa-triangle-exclamation',
                },
            ],
        },
        {
            name: 'Suppliers',
            to: 'suppliers',
            icon: 'fa-solid fa-lg fa-handshake',
        },
        {
            name: 'Warehouses',
            to: 'warehouses',
            icon: 'fa-solid fa-lg fa-warehouse',
        },
        {
            name: 'Fulfillment Centers',
            to: 'fulfillment-centers',
            icon: 'fa-solid fa-lg fa-dolly',
        },
        {
            name: 'Customer Support',
            heading: true,
        },
        {
            name: 'Orders',
            to: 'store-orders',
            icon: 'fa-solid fa-lg fa-receipt',
            subActivePaths: 'store-orders',
            sub: [
                {
                    name: 'All Orders',
                    to: {
                        name: 'store-orders',
                    },
                    icon: 'fa-solid fa-lg fa-clipboard-list',
                },
                {
                    name: 'Problem Orders',
                    to: {
                        name: 'store-orders',
                        query: {
                            problems: true,
                        },
                    },
                    icon: 'fa-solid fa-lg fa-bug',
                },
            ],
        },
        {
            name: 'Disputes',
            to: 'disputes',
            icon: 'fa-solid fa-lg fa-scale-balanced',
        },

        {
            name: 'DEBUGGING',
            heading: true,
        },
        {
            name: 'Health',
            to: 'health',
            icon: 'fa-solid fa-lg fa-heart-pulse',
        },
        {
            name: 'Report Requests',
            to: 'report-requests',
            icon: 'fa-solid fa-lg fa-file-lines',
        },
    ],
} as Menu;
