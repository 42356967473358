import LaravelError from '@/api/model/LaravelError';
import { useAuthStore } from '@/stores/authStore';
import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress';

// Main layout variations
import LayoutBackend from '@/layouts/variations/Backend.vue';
import LayoutBackendFull from '@/layouts/variations/BackendFull.vue';
import LayoutSimple from '@/layouts/variations/Simple.vue';

const Dashboard = () => import('@/views/dashboard/DashboardPage.vue');

const DisputesList = () => import('@/views/disputes/DisputesList.vue');

const ForecastsList = () => import('@/views/forecasts/ForecastsList.vue');

const FulfillmentCenterList = () => import('@/views/fulfillment_centers/FulfillmentCenterList.vue');
const FulfillmentCenterEdit = () => import('@/views/fulfillment_centers/FulfillmentCenterEdit.vue');

const HealthPage = () => import('@/views/health/HealthPage.vue');

const InventoryByDate = () => import('@/views/inventory/InventoryByDate.vue');

const ShipmentsView = () => import('@/views/shipments/ShipmentsView.vue');

const ProductList = () => import('@/views/products/ProductList.vue');
const ProductShow = () => import('@/views/products/ProductShow.vue');
const ProductEdit = () => import('@/views/products/ProductEdit.vue');

const PurchaseOrderList = () => import('@/views/purchase_orders/PurchaseOrderList.vue');
const PurchaseOrderEdit = () => import('@/views/purchase_orders/PurchaseOrderEdit.vue');

const ReportRequestList = () => import('@/views/report_requests/ReportRequestList.vue');
const ReportRequestStatus = () => import('@/views/report_requests/ReportRequestStatus.vue');

const ReportSourcesCreate = () => import('@/views/report_sources/ReportSourcesCreate.vue');
const ReportSourcesEdit = () => import('@/views/report_sources/ReportSourcesEdit.vue');

const SalesChannelsList = () => import('@/views/sales_channels/SalesChannelList.vue');
const SalesChannelsEdit = () => import('@/views/sales_channels/SalesChannelEdit.vue');

const StoreOrderList = () => import('@/views/store_orders/StoreOrderList.vue');

const WarehouseTransfersList = () => import('@/views/warehouse_transfers/WarehouseTransfersList.vue');
const WarehouseTransfersShow = () => import('@/views/warehouse_transfers/WarehouseTransferShow.vue');
const WarehouseTransfersEdit = () => import('@/views/warehouse_transfers/WarehouseTransferEdit.vue');

const SkuMapping = () => import('@/views/skus/SkuMappings.vue');
const SkuList = () => import('@/views/skus/SkuList.vue');
const SkuEdit = () => import('@/views/skus/SkuEdit.vue');
const SkuShow = () => import('@/views/skus/SkuShow.vue');

const Login = () => import('@/views/auth/Login.vue');
const Register = () => import('@/views/auth/Register.vue');
const ForgotPassword = () => import('@/components/auth/passwords/ForgotPassword.vue');
const ResetPassword = () => import('@/components/auth/passwords/ResetPassword.vue');
const CreatePassword = () => import('@/components/auth/passwords/CreatePassword.vue');
const StockAnalysis = () => import('@/views/analysis/StockAnalysis.vue');
const StockStatus = () => import('@/views/stocks/StockStatus.vue');

const SuppliersList = () => import('@/views/suppliers/SuppliersList.vue');
const SupplierAdd = () => import('@/views/suppliers/SupplierAdd.vue');
const SupplierEdit = () => import('@/views/suppliers/SupplierEdit.vue');
const SupplierProductsEdit = () => import('@/views/suppliers/SupplierProductsEdit.vue');

const WarehouseList = () => import('@/views/warehouses/WarehouseList.vue');
const WarehouseShow = () => import('@/views/warehouses/WarehouseShow.vue');
const WarehouseEdit = () => import('@/views/warehouses/WarehouseEdit.vue');
const WarehouseCreate = () => import('@/views/warehouses/WarehouseCreate.vue');

const AmazonOAuthForm = () => import('@/views/amazon/oauth/AmazonOAuthForm.vue');

const UserProfile = () => import('@/views/user/UserProfile.vue');

const OrganisationSettings = () => import('@/views/organisation/OrganisationSettings.vue');

const routes = [
    {
        path: '/organisation/:organisation',
        component: LayoutBackend,
        meta: { requiresAuth: true },
        children: [
            { path: '', name: 'dashboard', component: Dashboard },

            { path: 'disputes', name: 'disputes', component: DisputesList },

            { path: 'forecasts', name: 'forecasts', component: ForecastsList },
            {
                path: 'fulfillment-centers',
                name: 'fulfillment-centers',
                component: FulfillmentCenterList,
            },
            {
                path: 'fulfillment-centers/:id/edit',
                name: 'fulfillment-centers.edit',
                component: FulfillmentCenterEdit,
                props: getId,
            },

            {
                path: 'inventory/by-date',
                name: 'inventory-by-date',
                component: InventoryByDate,
            },

            { path: 'products', name: 'products', component: ProductList },
            {
                path: 'products/:id',
                name: 'products.show',
                component: ProductShow,
                props: getId,
            },
            {
                path: 'products/:id/edit',
                name: 'products.edit',
                component: ProductEdit,
                props: getId,
            },

            {
                path: 'purchase-orders/create',
                name: 'purchase-order.create',
                component: PurchaseOrderEdit,
            },
            {
                path: 'purchase-orders/:id/edit',
                name: 'purchase-order.edit',
                component: PurchaseOrderEdit,
                props: getId,
            },

            {
                path: 'report-requests',
                name: 'report-requests',
                component: ReportRequestList,
            },
            {
                path: 'report-requests/:id/status',
                name: 'report-requests.status',
                component: ReportRequestStatus,
                props: getId,
            },
            {
                path: 'report-requests/:id/process',
                name: 'report-requests.process',
                component: ReportRequestStatus,
                props: getId,
            },

            {
                path: 'report-sources/create',
                name: 'report-sources.create',
                component: ReportSourcesCreate,
            },
            {
                path: 'report-sources/:id/edit',
                name: 'report-sources.edit',
                component: ReportSourcesEdit,
                props: getId,
            },

            {
                path: 'sales-channels',
                name: 'sales-channels.list',
                component: SalesChannelsList,
            },
            {
                path: 'sales-channels/:id',
                name: 'sales-channels.edit',
                component: SalesChannelsEdit,
                props: true,
            },
            {
                path: 'settings',
                name: 'organisation.settings',
                component: OrganisationSettings,
            },

            {
                path: 'store-orders',
                name: 'store-orders',
                component: StoreOrderList,
            },
            {
                path: 'transfers',
                name: 'transfers',
                component: WarehouseTransfersList,
            },
            {
                path: 'transfers/:id',
                name: 'transfers.show',
                component: WarehouseTransfersShow,
                props: getId,
            },
            {
                path: 'transfers/:id/edit',
                name: 'transfers.edit',
                component: WarehouseTransfersEdit,
                props: getId,
            },
            { path: 'skus', name: 'skus', component: SkuList },
            {
                path: 'skus/:id',
                name: 'skus.show',
                component: SkuShow,
                props: getId,
            },
            {
                path: 'skus/:id/edit',
                name: 'skus.edit',
                component: SkuEdit,
                props: getId,
            },
            {
                path: 'skus/mapping',
                name: 'sku-mapping',
                component: SkuMapping,
            },
            {
                path: 'stock/analysis',
                name: 'stocks-analysis',
                component: StockAnalysis,
            },
            { path: 'stock', name: 'stocks', component: StockStatus },
            { path: 'stock/health', name: 'health', component: HealthPage },

            { path: 'suppliers/', name: 'suppliers', component: SuppliersList },
            {
                path: 'suppliers/create',
                name: 'suppliers.create',
                component: SupplierAdd,
            },
            {
                path: 'suppliers/:id/edit',
                name: 'suppliers.edit',
                component: SupplierEdit,
                props: getId,
            },
            {
                path: 'suppliers/:id/edit-products',
                name: 'suppliers.edit-products',
                component: SupplierProductsEdit,
                props: getId,
            },

            {
                path: 'warehouses',
                name: 'warehouses',
                component: WarehouseList,
            },
            {
                path: 'warehouses/create',
                name: 'warehouses.create',
                component: WarehouseCreate,
            },
            {
                path: 'warehouses/:ulid',
                name: 'warehouses.show',
                component: WarehouseShow,
                props: getUlid,
            },
            {
                path: 'warehouses/:ulid/edit',
                name: 'warehouses.edit',
                component: WarehouseEdit,
                props: getUlid,
            },
        ],
    },
    {
        path: '/',
        component: LayoutBackend,
        meta: { requiresAuth: true },
        beforeEnter: (to: RouteLocationNormalized) => {
            // For user profile route, inject org context without changing URL
            if (to.name === 'user.profile') {
                to.params.organisation = localStorage.getItem('organisation') || 'unknown';
                return true;
            }

            if (!(to.fullPath === '/') || to.params.organisation) {
                return true;
            }

            return {
                name: 'dashboard',
                params: {
                    organisation: localStorage.getItem('organisation'),
                    ...to.params,
                },
                query: to.query,
            };
        },
        children: [
            {
                path: '/amazon/connect',
                name: 'amazon.oauth.connect',
                component: AmazonOAuthForm,
            },
            {
                path: '/user/profile',
                name: 'user.profile',
                component: UserProfile,
            },
        ],
    },

    {
        path: '/organisation/:organisation/stock/analysis',
        component: LayoutBackendFull,
        children: [
            {
                path: '',
                name: 'stocks-analysis',
                component: StockAnalysis,
                props: true,
            },
        ],
    },

    {
        path: '/organisation/:organisation/shipments',
        component: LayoutBackendFull,
        children: [{ path: '', name: 'shipments', component: ShipmentsView }],
    },

    {
        path: '/organisation/:organisation/purchase-orders',
        component: LayoutBackendFull,
        children: [{ path: '', name: 'purchase-orders', component: PurchaseOrderList }],
    },

    {
        path: '/auth',
        component: LayoutSimple,
        children: [
            {
                path: 'login',
                component: Login,
                name: 'login',
                meta: { requiresAuth: false },
            },
            {
                path: 'register',
                component: Register,
                name: 'register',
                meta: { requiresAuth: false },
            },
            {
                path: 'forgot-password',
                component: ForgotPassword,
                name: 'forgot-password',
                meta: { requiresAuth: false },
            },
            {
                path: 'reset-password/:token',
                component: ResetPassword,
                name: 'reset-password',
                props: true,
                meta: { requiresAuth: false },
            },
            {
                path: 'password/create',
                component: CreatePassword,
                name: 'auth-password',
                meta: { requiresAuth: false },
            },
        ],
    },
];

function getId(route: RouteLocationNormalized): { id: number } {
    return {
        id: parseInt(route.params.id as string),
    };
}

function getUlid(route: RouteLocationNormalized): { ulid: string } {
    return {
        ulid: route.params.ulid as string,
    };
}

// Create Router
const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    linkExactActiveClass: '',
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
    routes,
});

// NProgress
NProgress.configure({ showSpinner: false });

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }

    next();
});

router.beforeEach(async (to) => {
    if (!to.meta.requiresAuth) {
        console.debug("Doesn't need auth, skipping...");
        return true;
    }

    const authStore = useAuthStore();

    try {
        await authStore.getCurrentUser();
    } catch (error) {
        if (error instanceof LaravelError && error?.message && error?.message === 'Unauthenticated') {
            console.info('User is not logged in, redirecting to login...');
            return { name: 'login' };
        }
    }

    if (authStore.auth.loggedIn) {
        console.info('User is logged in, continuing...');
        return true;
    }
    console.info('User is not logged in, redirecting to login...');
    return { name: 'login' };
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
