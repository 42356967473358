import type { ICountry } from '@/api/model/Country';
import Country from '@/api/model/Country';
import type { WarehouseSKUJson } from '@/api/model/WarehouseSKU';
import WarehouseSKU from '@/api/model/WarehouseSKU';
import type { WarehouseTransferJson } from '@/api/model/WarehouseTransfer';
import WarehouseTransfer from '@/api/model/WarehouseTransfer';
import type { IReportSource } from './ReportSource';
import ReportSource from './ReportSource';
import type { ISKU, WithWarehouseSKU } from './SKU';
import SKU from './SKU';
import { DateTime } from 'luxon';

export interface IWarehouse {
    id?: number;
    ulid: string;
    created_at: string;
    updated_at: string;
    name: string;
    foreign_id: string;
    laravel_through_key: number;
    report_source: IReportSource;
    report_source_id: number;
    skus: ISKU[];
    incoming_transfers: WarehouseTransferJson[];
    outgoing_transfers: WarehouseTransferJson[];
    countries: ICountry[];
    warehouse_sku: WarehouseSKUJson | null;
    is_distribution_center: boolean;
    is_fulfillment_center: boolean;
}

export type WithTransfers = {
    incoming_transfers: WarehouseTransfer[];
    outgoing_transfers: WarehouseTransfer[];
};

export default class Warehouse {
    id: number = 0;
    ulid = '';
    created_at: DateTime = DateTime.local();
    updated_at: DateTime = DateTime.local();
    name = '';
    foreign_id?: string;
    laravel_through_key?: number;
    report_source_id: number = 0;
    skus: Array<SKU & WithWarehouseSKU> = [];
    countries: Country[] = [];
    is_distribution_center: boolean = false;
    is_fulfillment_center: boolean = false;

    toJSON(): IWarehouse {
        //console.debug("Warehouse.toJSON()", this);
        return Object.assign({} as IWarehouse, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: IWarehouse): Warehouse {
        //console.debug("Warehouse.fromJSON()", json);
        const warehouse = Object.create(Warehouse.prototype);
        return Object.assign(warehouse, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            report_source: json.report_source ? ReportSource.fromJSON(json.report_source) : undefined,
            skus: json.skus ? json.skus.map((sku) => SKU.fromJSON(sku)) : [],
            warehouse_sku: json.warehouse_sku ? WarehouseSKU.fromJSON(json.warehouse_sku) : undefined,
            incoming_transfers: json.incoming_transfers
                ? json.incoming_transfers.map((transfer) => WarehouseTransfer.fromJSON(transfer))
                : [],
            outgoing_transfers: json.outgoing_transfers
                ? json.outgoing_transfers.map((transfer) => WarehouseTransfer.fromJSON(transfer))
                : [],
            countries: json.countries ? json.countries.map((country) => Country.fromJSON(country)) : [],
            is_distribution_center: json.is_distribution_center ?? false,
            is_fulfillment_center: json.is_fulfillment_center ?? false,
        });
    }
}
